import { acceptHMRUpdate, defineStore } from "pinia";

type CustomerInput = {
  gender: string;
  cpf: string;
  name: string;
  birth: string;
  email: string;
  phone: string;
  optional_phone_1?: string;
  optional_phone_2?: string;
};

export const useDeviceStoreV1 = defineStore("device-store-v1", {
  state: () => ({
    _id: "",
    status: [],
    shipping: {
      price: "",
      service: "",
      days: "",
    },
    customer: {
      name: "",
      phone: "",
      cpf: "",
      birth: "",
      email: "",
      optional_phone_1: "",
      optional_phone_2: "",
      gender: "",
    } as CustomerInput,
    address: {
      city: "",
      address: "",
      state: "",
      add_on: "",
      neighborhood: "",
      number: "",
      postcode: "",
    },
    devices: {},
    pos: {},
    provider: {},
    saleEndPayload: {},
  }),
  actions: {
    setUserDataPayload(customer: CustomerInput, address: any) {
      this.customer = {
        ...this.customer,
        ...customer,
      };
      this.address = {
        ...this.address,
        ...address,
      };
    },

    resetStore() {
      this.$reset();
    },

    setCreditAnalysis(credit_analysis: any) {
      this.credit_analysis = {
        ...this.credit_analysis,
        ...credit_analysis,
      };
    },
    setDevice(device: any) {
      this.devices = {
        ...this.devices,
        ...device,
      };
    },
    setCustomizeInvoicePayload(payload: any) {
      this.payment = {
        ...this.payment,
        ...payload,
      };
    },

    setPos(pos) {
      this.pos = {
        ...this.pos,
        ...pos,
      };
    },

    setProvider(provider) {
      this.provider = {
        ...this.provider,
        ...provider,
      };
    },
    addSaleIdFromDraft(_id: string) {
      this._id = _id;
    },

    addSaleStatusFromDraft(status: any) {
      this.status = status;
    },

    async sendSaleToDraft(step: number, axios: any, status = null) {
      return await axios.post("sales/draft", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "APARELHO",
          status: status || this.status,
          _id: this._id,
        },
      });
    },
    async sendCanceledSale(step: number, axios: any, status = "") {
      return await axios.post("sales/cancel", {
        sale: {
          ...this.payloadSale,
          step: step,
          type: "APARELHO",
          status,
          _id: this._id,
        },
      });
    },
    setSaleEndPayload(payload: any) {
      this.saleEndPayload = payload;
    },
  },
  getters: {
    payloadSale(): any {
      return {
        customer: {
          cpf: this.customer.cpf?.replace(/[^\d]+/g, ""),
          addresses: [
            {
              ...this.address,
            },
          ],
          phone: this.getFormattedPhone,
          name: this.customer.name,
          birth: this.customer.birth,
          email: this.customer.email,
          optional_phone_1: this.customer.optional_phone_1,
          optional_phone_2: this.customer.optional_phone_2,
          gender: this.customer.gender,
        },
        devices: {
          uuid: this.devices.uuid,
          provider: {
            uuid: this.provider.uuid,
          },
        },
        pos: {
          uuid: this.pos.uuid,
        },
        type: "APARELHO",
      };
    },

    getFormattedPhone(): string {
      if (this.customer.phone.startsWith("+55")) {
        return this.customer.phone;
      }

      return "+55".concat(this.customer.phone.replace(/[^\d]+/g, ""));
    },
  },
  persist: {
    storage: persistedState.sessionStorage,
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceStoreV1, import.meta.hot));
}
